import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HeroLayout } from "../components/hero-layout";
import { getQueryParam } from "../utils/query";

export const WarningPage = ({ location }) => {
  const message =
    getQueryParam("message", location) ||
    (location.state && location.state.message);

  return (
    <Layout withMaxWidth={false}>
      <SEO title={"Warning"} />
      <HeroLayout>
        <div className="text-content-container confirm-email">
          <h2 className="mdc-typography--headline4">
            {message}
          </h2>
        </div>
      </HeroLayout>
    </Layout>
  );
};

export default WarningPage;
